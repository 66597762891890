.resourcesBannerBlock{
    .minWidth{
            max-width: 608px;
            margin: auto;
    }
    .resourcesBanner{
        margin-top: 40px;
        .bannerImageBlock{
            height: 560px;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 40px;
            img{
                height: 100%;
                object-fit: cover;
            }
            @media screen and (width < 575px){
                height: 400px;
            }

        }
        p{
            font-family: Sora;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #888888;
            @media screen and (width < 767px){
                font-size: 16px;
            }

        }
    }




}
