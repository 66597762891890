.questionFormBlock{
    width: calc(100vw - 18px);
    transform: translateX(-50%);
    margin-left: 50%;
    background: #fbfbfb;
    padding: 64px 0;
    .questionForm{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 20px;
        max-width: 1270px;
        margin: 72px auto 0;
        padding: 32px 15px;
        background: #fff;
        border-radius: 16px;
        .fieldInput{
            position: relative;
            input, select, textarea{
                height: 58px;  
                border-radius: 16px;
                background-color: #F6F8F9;
                border: 0;
                box-shadow: unset;
            }
        &:is(.textAreaField){
            grid-column: span 2; 
            textarea{
               min-height: 180px; 
            }
        }   
        span{
            font-size: 14px;
            position: absolute;
            bottom: -25px;
        }     
        }
        .checkBoxTerm{
            display: flex;
            gap: 15px;
            input[type="checkbox"] {
                max-width: fit-content;
                height: 22px;
                accent-color: #262BBA;
            }
        }
        .button{
            text-align: right;
        }
        @media screen and (width < 575px){
            display: block;
            .fieldInput{
                margin-bottom: 25px;
            }
            .button{
                text-align: center;
                margin-top: 25px;
                button{
                    width: 100%;
                }
            }
        }
    }
}


