@import '../../styles/customMediaQueries.css';

.root {
  padding: 32px 0;
  position: relative;
  word-break: break-word;
  background-color: #fff;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
} 

.container {
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.title {
  text-align: center;
}

.pricingTableStripe {
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
  }
}

.pricingTableItem {
  
  @media (--viewportMedium) {
    width: 50%;
  }
}

.pricingTableItemCustom {
  padding: 4px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.pricingBlock {
  width: 100%;
  max-width: 256px;
  height: 100%;
  max-height: 260px;
  padding: 34px 36px 28px;
  margin: 0 auto;
  border-radius: 12px;
  border: 1px solid rgba(26, 26, 26, 0.1);
  background-color: rgba(26, 26, 26, 0.05);
}

.pricingBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricingBlockRecommended {
  width: 100%;
  height: 19px;
  margin-bottom: 19px;
}

.pricingBlockTitle {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  color: #1a1a1a;
}

.pricingBlockPrice {
  font-weight: var(--fontWeightBold);
  color: #1a1a1a;
  font-size: 36px;
  line-height: 42px;
}

.pricingBlockButton {
  width: 100%;
  margin-top: 16px;
  padding: 12.5px 24px;
  border-radius: 6px;
  background-color: #0074d4;
  color: var(--colorWhite);
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}
