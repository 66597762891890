.headerExpPartnership{
    max-width: 400px;
    margin: 128px auto 0;
    h2 {
        color: #333333;
    }
    @media screen and (width < 992px){
        margin-top: 64px;
    }
}

.expData{
display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 40px 0 32px;
    .expCard {
        flex: 0 0 25%;
        text-align: center;
        max-width: calc(100% / 4 - 30px);
        border: 1px solid #E3E3E3;
        border-radius: 12px;
        padding: 27px 15px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &:hover{
            border-color: #262BBA;
        }
    }

    @media screen and (width < 992px){
        gap: 15px;
        .expCard {
            max-width: calc(100% / 4 - 15px);
        }
    }
    @media screen and (width < 992px){
        flex-wrap: wrap;
        .expCard {
            max-width: calc(100% / 2 - 15px);
            flex: 0 0 50%;
        }
    }
    @media screen and (width < 992px){
        flex-wrap: wrap;
        .expCard {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.expVectorContainer{
    img{
        max-width: 45px;
    }
}