.partnerPage{
    max-width: 1270px;
    margin: 64px auto 0;
    padding: 0 15px;

    .bgColor{
        background: #262BBA0D;
        width: 99vw;
        transform: translateX(-50%);
        margin-left: 50%;
        padding: 0 15px;
    }
}


