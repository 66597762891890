@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  margin: 0 auto;
  border-radius: var(--borderRadiusBig);
  border: 1px solid var(--colorGrey500);
  padding: 20px 20px;
  background-color: #fff;
  
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    height: 64px;
    max-width: 760px;
    padding: 10px 10px 10px 20px;
    border-radius: var(--borderRadiusMax);
  }
}

.rootTopbar {

  & .locationAutocompleteInput,
  & .dateLabelsBottom label {
    font-size: 16px;
  }

  & .datesFilter {
    
    @media (--viewportLarge) {
      padding-left: 10px;
    }
  }

  & .dateLabelsTop,
  & .dateLabelsBottom {

  & label:last-child {
      padding-left: 10px;
    }
  }
}

.rootForm {
  flex-basis: 100%;
  
  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.locationAddress {
  width: 100%;
  height: 50px;
  position: relative;
  margin-bottom: 20px;
  
  @media (--viewportLarge) {
    max-width: 360px;
    margin-bottom: 0;
  }

  & label {
    position: absolute;
    left: 0;
    top: 5px;
    padding: 0;
    z-index: 1;
    font-size: 14px;
    line-height: 1;
    font-weight: var(--fontWeightBold);
    color: #7B7B7B;
  }

  & > div {
    height: 100%;
  }
  & > div + div {
    position: absolute;
    width: calc(100% + 180px);
    margin: 0;
    transform: translateY(10px);
  }
}

.locationAutocompleteInput {
  min-height: 100%;
  line-height: 1;
  border: none;
  border-radius: 0;
  padding: 15px 10px 0 0;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  
  &:hover,
  &:focus {
    border: none;
  }

  &::placeholder {
    color: var(--colorGrey700);
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.searchFooter {
  margin: 20px 0 0;
}

.datesFilter {
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  
  @media (--viewportLarge) {
    border-left: 1px solid var(--colorGrey500);
    max-width: 320px;
    padding: 0 14px;
    margin-bottom: 0;
  }
}

.dates {
  position: relative;
  width: 100%;
  height: 100%;
}
  
.dateLabels {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 1;
  padding: 5px 0 7px;

  & label {
    width: 50%;
    padding: 0;
    font-size: 14px;
    line-height: 1;
    font-weight: var(--fontWeightBold);
  }
}

.dateLabelsTop,
.dateLabelsBottom {
  display: flex;

  & label:last-child {
    padding-left: 14px;
  }
}

.dateLabelsTop {

  & label {
    color: #7B7B7B;
  }
}

.dateLabelsBottom {

  & label {
    font-size: 16px;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorGrey600);
  }

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: 50px;
    background-color: var(--colorGrey500);

    position: absolute;
    /* left: calc(50% - 10px); */
    left: 50%;
    top: 0;
  }
}

.datesHolder {
  display: none;
}

.datesHolderOpen {
  display: block;
  position: absolute;
  top: 60px;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorGrey500);
  border-radius: var(--borderRadiusBig);
  min-height: 300px;
  width: 100%;
  overflow: hidden;

  @media (--viewportLarge) {
    width: auto;
  }
  
  & :global(.DayPicker.DayPicker__horizontal) {
    visibility: visible !important;
    background-color: var(--colorWhite);
  }

  & :global(.DayPicker_transitionContainer__horizontal) {
    min-height: 320px;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  padding: 0;

  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  margin: 0;
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}


.submitButtonMain {
  flex-shrink: 0;
  min-height: 48px;
  height: 48px;
  width: 100%;
  border-radius: var(--borderRadiusMax);
  
  @media (--viewportLarge) {
    width: 48px;
    border-radius: 50%;
  }
} 

