@import '../../../styles/customMediaQueries.css';

.baseBtn{

    border: 0;
    padding: 12px 24px;
    border-radius: 12px;
    height: 48px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: sora;
    cursor: pointer;
    @media (--viewportSmall){
        font-size: 16px;
    }
    svg{
        fill: transparent;
        path{
            stroke: #ffffff;
        }
    }
}

.primary{
    background: linear-gradient(75.73deg, #FFBC42 -54.23%, #FEAC48 -10.27%, #FC695F 80.26%);
}


.secondary {
    background-color: var(--marketplaceColor);
    color: white;
    position: relative;
  }

  .secondary:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-50%) scaleX(0);
    transition: 0.3s ease all;
    background: rgba(255, 255, 255, 0.1);
    transform-origin: center center;
}

.secondary:hover:after {
    transform: translateX(-50%) scaleX(1);
}
.whitebg{
    background-color: #fff;
    border: 1px solid var(--marketplaceColor);
    color: var(--marketplaceColor);
    z-index: 0;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        width: 101%;
        transform: translateX(-50%) scaleX(0);
        transition: 0.3s ease all;
        background-color: var(--marketplaceColor);
        z-index: 0;
        transform-origin: center center;
        z-index:-1;
    }
    &:hover{
        color: #ffffff;
        &:after{
            z-index:-1;
        }
    }
}