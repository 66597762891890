.resourcesToolsBlock{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 64px;
    gap: 90px;
    .imageColumn{
        position: relative;
        border-radius: 16px;
        overflow: hidden;
            .imageBlock{
                    position: relative;
                    height: 100%;
                  &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        z-index: 1;
                        left: 0;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    }
                    img{
                        height: 100%;
                        object-fit: cover;
                    }
            }
            h3{
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                max-width: 400px;
                margin: auto;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: Sora;
                font-size: 40px;
                font-weight: 700;
                line-height: 56px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #fff;
                z-index: 1;
                @media screen and (width < 767px){
                    font-size: 30px!important;
                    line-height: normal;
                padding: 0 30px;
                }
            }
            &.topPostion{
                h3{
                    bottom: unset;
                    top: 15%;
                }
            }
    }
    .toolListcolumn{
         .headerBlock{
            *{
                text-align:left;
            }
            h2{
                @media screen and (width < 767px){
                    font-size: 30px!important;
                }
                }
         }
         .toolPoints{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: 40px 0;
            gap: 24px;
            .CareAsstCheckboxContainer{
                display: flex;
                gap: 8px;
                align-items: center;
                input[type="checkbox"]{
                    border-radius: 50%;
                    max-width: max-content;
                        position: relative;
                        appearance: none;
                        width: 18px;
                        height: 18px;
                        min-width: 18px;
                        &:after{
                            content: '✓';
                            position: absolute;
                            color: #fff;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 12px;
                        }
                    &:checked{
                        background-color: #222430;
                        border-color: #222430;
                        
                       
                    }
                  
                }
                label{
                    padding: 0;
                    font-family: Sora;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #666666;

                }
            }
            @media screen and (width < 767px){
                gap: 15px;
            }
         }
         p{
            text-align: left;
            line-height: 32px;
         }
         .viewDetailsButton{
            margin-top: 40px;
         }
    }

    @media screen and (width < 1024px){
        gap: 15px;
    }
    @media screen and (width < 992px){
      grid-template-columns: repeat(1, 1fr);
      + .resourcesToolsBlock {
        flex-direction: column-reverse;
        display: flex;
        + .resourcesToolsBlock {
            flex-direction: column;
            display: flex;
           
          }
      }
    
    }
}