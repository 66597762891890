@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px; border-right-width:0px;
    border-style: solid;
    border-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--colorGrey50);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 48px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    width: unset;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px 0 82px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}


/*  */
.container{
  max-width:1530px;
  margin: auto;
  padding: 0 15px;
  position: relative;
  /* overflow: hidden; */
  &:after{
    content: '';
    position: absolute;
    width: calc(100vw - 20px);
    background-color:#262BBA40;
    height: 1px;;
   
  }
  .sideNav{
      padding: 24px;
        flex: 0 0 30%;
        max-width: 320px;
        width: 320px;
        @media screen and (width < 1024px){
          position: fixed;
          z-index: 9;
          left: -100%;
          transition: all 0.2s linear;
          height: calc(100vh - 72px);
          overflow: auto;

        }
        @media screen and (width < 767px){        
          height: calc(100vh - 60px);
        }
     
  }
  .mobileClass{
    left: 0;
  }
  .main{
    padding: 48px calc(50% - 528px) 82px 59px;
    max-width: calc(100% - 320px);
    @media screen and (width < 1024px){
      max-width: 100%;
      padding-left: 0;
    }
  
  }
}