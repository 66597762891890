.blogPage{
    /* max-width: 1630px; */
    max-width: 1270px;
    padding: 0 15px;
    margin: 0 auto 50px;
    width: 100%;
    margin-top: 64px;
    .headerWrapper{
        max-width: 608px;
        width: 100%;
        margin: auto;   
        h2{
            text-align: center;
            span{
                color: var(--marketplaceColor);
            }
        }
        p{
            text-align: center;
            font-family: Sora;
font-size: 16px;
font-weight: 400;
line-height: 32px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #666666;

        }
    }

    @media screen and (width <= 1440px){
        /* max-width: 1270px; */
        
    }
}
