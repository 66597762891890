@import '../../styles/customMediaQueries.css';

.root {
  margin: 24px 0 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
  margin-bottom: 24px;
  .avatar {
    width: 48px;
    height: 48px;
  }
  .customerReview {
    .reviewInfo {
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #212121;
      margin-bottom: 8px;
    }
    .reviewContent {
      font-family: Inter;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 22.4px;
      text-align: left;
      color: #212121;
    }
  }
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  composes: textSmall from global;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--colorGrey300);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
.avatarWrapper {
  margin-right: 22px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--colorWhite);
}
