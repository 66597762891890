@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.videoContent {
  width: 100%;
  max-height: 350px;
  min-height: 350px;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;

  @media (--viewportLarge) {
    max-height: 540px;
    min-height: 540px;
  }
}

.overlayContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}
