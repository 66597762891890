.faqBlock {
  &:has(.workWithPoints){
    padding: 0px;
  }
}
.faqSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  max-width: 1270px;
  margin: 0 auto;
  padding: 80px 15px;
  @media screen and (width <= 1440px) {
    max-width: 1200px;
  }
  @media screen and (max-width: 1199px) {
    gap: 15px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &:has(.workWithPoints){
    padding: 80px 0px;
    @media screen and (width < 992px){
      flex-direction: column!important;
      display: block;
    }
  }
}

.imageContainer {
}

.faqSideImage {
}

.faqAccourding {
}

.faqAccourding h2 {
  font-family: Sora;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 16px;
  @media screen and (max-width: 991px) {
    font-size: 25px;
  }
}

.faqAccourding p {
  font-family: Sora;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(102, 102, 102, 1);
}
.accordionFaq {
  margin-top: 40px;
}

.accordionItem {
  padding: 24px;
  border: 2px solid rgba(246, 248, 249, 1);
  border-radius: 18px;
  margin-bottom: 24px;
  @media screen and (max-width: 991px) {
    padding: 15px;
  }
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordionBody {
  margin-top: 16px;
}
.accordionBody p {
  font-size: 18px;
}
.icon {
  font-size: 30px;
  font-weight: 200;
}

.faqQuestion {
  font-family: Sora;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(27, 33, 36, 1);
}

.content {
}

.content button {
  width: 100%;
  max-width: 100%;
  margin-top: 15px;
}

@media screen and (max-width: 991px) {
  .faqQuestion {
    font-size: 18px;
  }
}

.resourcesFaq {
  padding: 0;
  .faqSection {
    padding: 102px 0px 80px;
    gap: 48px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @media screen and (width < 992px){
      flex-direction: column;
    }
    .imageContainer {
      flex: 1;
    }
    .faqAccourding {
      flex: 1;
      h2 {
        font-size: 40px;
        @media screen and (width < 767px){
          font-size: 30px;
          line-height: normal;
        }
      }
    }
    .workWithPoints{
      order: -1;
     
    }

  }
  @media screen and (width < 767px){

  }
}

.workWithPoints {
  flex: 1;
  order: 1;
  h2 {
    font-family: Sora;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    text-align: left;
    color: #262bba;
  }
  .pointsCards {
    .checkbocContainer {
      display: flex;
      align-items: center;
      background: #262bba0d;
      padding: 24px;
      border-radius: 12px;
      gap: 16px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .careAsstCheckboxValue {
        padding: 0;
        font-family: Sora;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        color: #666666;
      }
    }
    &.checkBoxGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
      gap: 24px;
      margin-top: 40px;
      .checkbocContainer {
        margin: 0;
        justify-content: center;
        min-height: 228px;
        
      }
      @media screen and (width < 575px){
        display: block;
        .checkbocContainer{
          margin-bottom: 25px;
        }
      }
    }
  }
  + .faqAccourding{
      .accordionItem{
        border: 2px solid #262BBA0D;
      }
  }
  input[type="checkbox"]{
    border-radius: 50%;
    max-width: max-content;
        position: relative;
        appearance: none;
        width: 18px;
        height: 18px;
        min-width: 18px;
        &:after{
            content: '✓';
            position: absolute;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
        }
    &:checked{
        background-color: #262BBA;
        border-color: #262BBA;
        
       
    }
  
}
}


@media screen and (width < 767px){
  .accordionBody p {
    font-size: 14px;
  } 
}