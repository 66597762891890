@import '../../styles/customMediaQueries.css';

.root {
  /* display: none; */
  display: flex;
  justify-content: flex-start;
  height: 57px;
  align-items: flex-start;
  padding: 13px 24px 0 24px;
  gap:15px;
  @media (--viewportMedium) {
    justify-content: flex-end;
    align-items:center;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
