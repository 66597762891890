@import '../../styles/customMediaQueries.css';

.root {


  & a {
    display: inline-block;
    margin-top: 8px;
    color: var(--colorWhite);
    text-decoration: underline;
    font-weight: var(--fontWeightBold);

    &:hover,
    &:focus {
      color: var(--marketplaceColorYellow);
    }
  }
}

.formTitle {
  padding: 0;
  margin: 0 0 24px;
  line-height: 1;
  font-weight: 800;
  text-decoration: underline;
  color: var(--colorWhite);
}

.formDescription {
  padding: 0;
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightSemiBold);
}

.formField {
  margin: 0 0 16px;

  & input {
    padding: 4px 10px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--colorWhite);
    border-radius: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: var(--fontWeightSemiBold);

    &::placeholder {
      color: var(--colorWhite);
    }
  }

  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--marketplaceColor) inset;
    box-shadow: 0 0 0px 1000px var(--marketplaceColor) inset;
    -webkit-text-fill-color: var(--colorWhite);
    color: var(--colorWhite);
  }
  
  & input:-moz-autofill {
    box-shadow: 0 0 0px 1000px var(--marketplaceColor) inset;
    color: var(--colorWhite);
  }

  & input:-webkit-autofill,
  & input:-moz-autofill {
    -webkit-text-fill-color: var(--colorWhite);
    color: var(--colorWhite);
  }
}

.formButton {
  max-width: 200px;

  & button {
    font-weight: var(--fontWeightBold);
  }
}
