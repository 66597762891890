@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
@import '../assets/sanitize.css';
@import './customMediaQueries.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  /* Colors used for different functions (like error texts) */

  --colorSuccess: #2ecc71;
  --colorSuccessDark: #239954;
  --colorSuccessLight: #f0fff6;
  --colorFail: #ff0000;
  --colorFailLight: #fff0f0;
  --colorAttention: #ffaa00;
  --colorAttentionLight: #fff7f0;
  --colorBannedLight: var(--marketplaceColorLight);
  --colorBannedDark: var(--marketplaceColor);

  /* B&W and Grey palette */

  --colorWhite: hsl(0 0% 100%);
  --colorGrey50: hsl(240 3% 98%);
  --colorGrey100: hsl(240 3% 90%);
  --colorGrey200: hsl(240 3% 80%);
  --colorGrey300: hsl(240 3% 70%);
  --colorGrey400: hsl(240 3% 60%);
  --colorGrey500: hsl(240 3% 50%);
  --colorGrey600: hsl(240 3% 40%);
  --colorGrey700: hsl(240 3% 30%);
  --colorGrey800: hsl(240 3% 20%);
  --colorGrey900: hsl(240 3% 10%);
  --colorBlack: hsl(0 0% 0%);

  --colorLightTransparent: rgba(255, 255, 255, 0.65);

  /**
  * Marketplace color comes by default from src/config/brandingConfig.js
  * It overwrites these CSS Properties.
  * In the future, we are planning to bring more --marketplace* CSS Properties
  * from hosted assets, which means that they could be managed through Console.
  */
  --marketplaceColor: #262bba;
  --marketplaceColorLight: #262bba;
  --marketplaceColorDark: #262bba;
  --commonLight: #e8f5ff;
  --commonHover: #ffbc42;
  /** 
   * The fill color used by <PrimaryButton>
   * These might be overwritten by values from hosted assets
   */
  --colorPrimaryButton: var(--colorSuccess);
  --colorPrimaryButtonDark: var(--colorSuccessDark);
  --colorPrimaryButtonLight: var(--colorSuccessLight);

  /** 
   * The fill color used by <SecondaryButton>
   */
  --colorSecondaryButton: var(--colorWhite);
  --colorSecondaryButtonDark: var(--colorWhite);
  --colorSecondaryButtonLight: var(--colorWhite);

  /* ================ Font ================ */
  --fontFamily: 'sora';

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightHeavy: 800;
  --fontWeightBlack: 900;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing units ================ */

  /**
   * Note: changing --contentMaxWidth does not affect layouts with LayoutSideNavigation or search pages.
   */
  --contentMaxWidth: 1056px;
  --contentMaxWidthPages: 1120px; /* calc(Global width (1056px) + (Horizontal padding (32px) * 2) */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMedium: 4px;
  --borderCommon: calc(2 * var(--borderRadiusMedium));
  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 72px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */
  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ Inputs, textareas and selects ================ */
  --borderErrorField: 1px solid var(--colorFail);

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;

  /* ================ PageBuilder ================ */
  /* --carouselWidth will be updated dynamically through JS */
  --carouselWidth: 100vw;
}

/* ================ Global element styles ================ */
/**
 * Here are the global styles: for elements as well as some
 * global classes that are composed into component styles.
 *
 * NOTE: components use CSS Modules for styles.
 */

body {
  font-family: var(--fontFamily);

  /* A non-standard feature: https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth */
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  text-rendering: optimizeSpeed;
  font-optical-sizing: auto;

  background-color: var(--colorWhite);
  color: var(--colorBlack);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--colorWhite);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--colorWhite);
}

.container {
  width: 100%!important;
  max-width: 1270px!important;
  margin: auto!important;
}

/* Default elements that are targets */
/* In addition, let's ensure that section components have scroll-margin */
/* Since target does not work in every situation */
:target,
section {
  scroll-margin-top: var(--topbarHeight);

  @media (--viewportMedium) {
    scroll-margin-top: var(--topbarHeightDesktop);
  }
}

a {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: var(--fontWeightBlack);
  padding: 3px 0 3px 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 0px;
  margin-bottom: 18px;
  @media(--viewportSmall){
    font-size: 40px;
    line-height: 40px;
  }
  @media (--viewportMedium) {
    font-size: 56px;
    line-height: 67px;
    padding: 0;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

h2 {
  font-size: 21px;
  line-height: 24px;
  font-weight: var(--fontWeightBold);
  margin-bottom: 18px;
  color: var(--colorBlack);

  @media(--viewportSmall){
    font-size: 30px;
    line-height: 40px;
  }

  @media (--viewportMedium){
    font-size: 25px;
    line-height: 40px;
  }
  @media (--viewportLarge) {
    font-size: 40px;
    line-height: 56px;
  }
  
  @media (--viewportLargeWithPaddings) {
    font-size: 48px;
    line-height: 56px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
}

h3 {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 24px;
  /* x * 6px */
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportLargeWithPaddings) {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px;
  }
}

h4 {
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 30px;
  padding: 1px 0 5px 0;
  /* x * 6px */
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 32px;
    padding: 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

h5 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  /* x * 6px */
  padding: 3px 0 3px 0;
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

h6 {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  /* x * 6px */
  padding: 4px 0 2px 0;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

html,
li,
p,
pre {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);

  /* No margins for default font */
  font-size: 14px;
  line-height: 24px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 27px;
  }
}

p,
pre {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-bottom: 12px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

/* ================ Normalisations ================ */

html {
  /* font is set earlier */
  color: var(--colorBlack);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend {
  display: block;

  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

label {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 13px;
  }
}

button {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
}

/* marketplaceInputFontStyles */
select,
input,
textarea {
  font-family: var(--fontFamily);
  font-size: 16px;
}

select {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 12px 32px 12px 16px;
  background-color: #fff;

  /* Borders */
  border-radius: 12px;
  border: 1px solid var(--marketplaceColor);
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  padding: 6px 24px 4px 12px; 

  /* Unset user agent styles */
  appearance: none;

  /* Background */
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2212%22%20viewBox%3D%220%200%2022%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.293%208.87876L1.70718%200.292969L0.292969%201.70718L8.87876%2010.293C8.89159%2010.3058%208.9044%2010.3186%208.9172%2010.3314C9.21701%2010.6314%209.50838%2010.9228%209.78106%2011.1309C10.0885%2011.3654%2010.4864%2011.5859%2011.0001%2011.5859C11.5137%2011.5859%2011.9117%2011.3654%2012.2191%2011.1309C12.4918%2010.9228%2012.7831%2010.6314%2013.0829%2010.3314C13.0957%2010.3186%2013.1086%2010.3058%2013.1214%2010.293L21.7072%201.70718L20.293%200.29297L11.7072%208.87876C11.3539%209.23208%2011.1585%209.42444%2011.0059%209.54084C11.0039%209.54236%2011.002%209.54384%2011.0001%209.54529C10.9982%209.54384%2010.9962%209.54236%2010.9942%209.54084C10.8417%209.42444%2010.6463%209.23208%2010.293%208.87876Z%22%20fill%3D%22%23262BBA%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-position: center right 12px;
  background-size: 8px 14px;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    padding: 7px 32px 7px 16px;
    line-height: 32px;
    background-position: center right 16px;
    background-size: 10px 16px;
  }
}
/* Effects */
select:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
select:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
select:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

input,
textarea {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px 16px 7px 16px;
    line-height: 32px;
  }
}
/* Effects */
input:hover,
textarea:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
input:focus,
textarea:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
input:disabled,
textarea:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

textarea {
  min-height: 84px; /* 3 rows  */
  max-height: 300px; /* 12 rows fullu visible, scroll after */

  @media (--viewportMedium) {
    min-height: 112px; /* 3 rows fully visible */
    max-height: 496px; /* 15 rows fullu visible, scroll after */
    line-height: 24px;
  }
}

/* ================ Plain global CSS glasses ================ */

/**
 * Note: even though these reflect element styles, 
 * these are below element styles to allow overwrites using classes.
 */

.a {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}
.a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.h1 {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 0;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 64px;
    line-height: 64px;
    padding: 0;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

.h2 {
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.h3 {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 24px;
  /* x * 6px */
  margin-top: 18px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.h4 {
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 30px;
  padding: 1px 0 5px 0;
  /* x * 6px */
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 32px;
    padding: 0;
    /* x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.h5 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  /* x * 6px */
  padding: 3px 0 3px 0;
  margin-top: 12px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.h6 {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  /* x * 6px */
  padding: 4px 0 2px 0;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* x * 8px */
    padding: 3px 0 5px 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.label {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 13px;
  }
}

.p,
.buttonDefault,
.buttonPrimary,
.buttonPrimaryInline,
.buttonSecondary,
.buttonSecondaryInline,
.marketplaceMessageFontStyles,
.marketplaceTxTransitionFontStyles,
.marketplaceListingAttributeFontStyles,
.marketplaceBodyFontStyles,
.marketplaceDefaultFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);

  /* No margins for default font */
  font-size: 14px;
  line-height: 24px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0 3px 0;
  }
}

.p,
.marketplaceBodyFontStyles {
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.textLarge {
  font-size: 20px;
  line-height: 30px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    line-height: 32px;
  }
}

.textSmall {
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.1px;

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.textXSmall {
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

/* ================ Other fonts ================ */

.marketplaceSmallFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.marketplaceTinyFontStyles {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
}

/* .marketplaceMessageFontStyles is defined earlier */
/* .marketplaceTxTransitionFontStyles is defined earlier*/
/* .marketplaceListingAttributeFontStyles is defined earlier */

.marketplaceMessageDateFontStyles {
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 12px;
    line-height: 24px;
  }
}

.marketplaceSearchFilterLabelFontStyles {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.marketplaceSearchFilterSublabelFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 18px;
}

/* ================ Global class for input styles ================ */

.marketplaceInputStyles {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* 6 + 24 + 4 + borders are divisible by 6 */
  padding: 6px 12px 4px 12px;
  background-color: #fff;

  /* Borders */
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  &::placeholder {
    color: var(--colorGrey300);
  }

  @media (--viewportMedium) {
    /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
    padding: 7px 16px 7px 16px;
    line-height: 32px;
  }
}
/* Effects */
.marketplaceInputStyles:hover {
  border: 1px solid #b8bfd1;
  transition: all ease-in-out 100ms;
}
.marketplaceInputStyles:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  transition: all ease-in-out 100ms;
}
.marketplaceInputStyles:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

/* ================ Tabbed navigation font styles ================ */

.marketplaceTabNavFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;
  }
}

.marketplaceTabNavHorizontalFontStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

/* ================ Clearfix solution ================ */

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/* ================ Buttons ================ */

/**
 * For actual buttons, use components: <Button>, <PrimaryButton>, <SecondaryButton>
 *
 * To create button look-and-feel for links, etc. combine button classes in CSS Modules:
 * 
 * <Button>:
 *   composes: buttonDefault from global;
 * <PrimaryButton>:
 *   composes: buttonPrimary from global;
 * <SecondaryButton>:
 *   composes: buttonSecondary from global;
 * <SecondaryButtonInline>:
 *   composes: buttonSecondaryInline from global;
 */

/**
 * Styles for <Button>
 */
.buttonDefault {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 16px 0 16px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  /* Default button has no borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  /* Button colors */
  /* Default button uses marketplace color */
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}
.buttonDefault:focus,
.buttonDefault:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceColorDark);
}
.buttonDefault:disabled {
  box-shadow: none;
  cursor: not-allowed;
  text-decoration: none;
  background-color: var(--colorGrey100);
  color: var(--colorWhite);
}

/**
 * Styles for <PrimaryButton>
 */
.buttonPrimary {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 16px 0 16px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);

  /* Button text styles */
  /* Primary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  /* Primary button has no borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  /* Button colors */
  /* Primary button uses success color */
  background-color: var(--colorPrimaryButton);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 20px 0 20px 0;
  }
}
.buttonPrimary:focus,
.buttonPrimary:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--colorPrimaryButtonDark);
  color: var(--colorWhite);
}
.buttonPrimary:hover {
  text-decoration: none;
}
.buttonPrimary:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
}

/**
 * Styles for <PrimaryButtonInline>
 */
.buttonPrimaryInline {
  display: inline-block;
  padding: 8px 16px;
  margin: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);

  /* Button text styles */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: none;
  border-radius: var(--borderRadiusMedium);

  background-color: var(--colorPrimaryButton);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    line-height: 16px;
  }
}
.buttonPrimaryInline:focus,
.buttonPrimaryInline:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  text-decoration: none;
  background-color: var(--colorPrimaryButtonDark);
  color: var(--colorWhite);
}
.buttonPrimaryInline:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--colorGrey100);
  color: var(--colorGrey700);
}

/**
 * Styles for <SecondaryButton>
 */
.buttonSecondary {
  /* button defaults */
  display: block;
  width: 100%;
  min-height: 56px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 16px 0 16px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font: most font styles are defined earlier in this file
   */
  font-weight: var(--fontWeightSemiBold);

  /* Button text styles */
  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Secondary button has clear borders */
  border-width: 1px;
  border-style: solid;
  border-color: var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  /* Button colors: secondary */
  background-color: var(--colorSecondaryButton);
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    padding: 20px 0 20px 0;
  }
}
.buttonSecondary:focus,
.buttonSecondary:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  border-color: var(--colorGrey300);
  background-color: var(--colorSecondaryButton);
}
.buttonSecondary:hover {
  text-decoration: none;
}
.buttonSecondary:disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--colorGrey100);
  background-color: var(--colorGrey100);
  color: var(--colorWhite);
}

/**
 * Styles for <SecondaryButtonInline>
 */
.buttonSecondaryInline {
  /* button defaults */
  display: inline-block;
  width: auto;
  min-height: auto;
  height: 42px;
  margin: 17px 0 0 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 0 16px;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /*
   * Font
   */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Button text styles */
  /* Secondary button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Secondary button has clear borders */
  border-width: 1px;
  border-style: solid;
  border-color: var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  /* Button colors: secondary */
  background-color: var(--colorSecondaryButton);
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    height: 40px;
  }
}
.buttonSecondaryInline:focus,
.buttonSecondaryInline:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
  border-color: var(--colorGrey300);
  background-color: var(--colorSecondaryButton);
}
.buttonSecondaryInline:hover {
  text-decoration: none;
}
.buttonSecondaryInline:disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: var(--colorGrey100);
}

/* ================ Modals ================ */

.marketplaceModalRootStyles {
  /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

  /* AuthenticationPage's root uses flexbox */
  display: flex;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
  }
}

.marketplaceModalFormRootStyles {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}

.marketplaceModalBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--colorWhite);
  border-radius: var(--borderRadius);
  border-bottom: none;

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    /* min-height: 568px; */
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    border-bottom: 8px solid var(--marketplaceColor);
  }
}

.marketplaceModalInMobileBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--colorWhite);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

/* Modal title */
.marketplaceModalTitleStyles {
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  line-height: 36px;
  margin: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightBold);
    line-height: 40px;
    margin: 0;
  }
}

/* Modal message */
.marketplaceModalParagraphStyles {
  font-size: 20px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }
}

/* Bottom wrapper for the modal */
.marketplaceModalBottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.marketplaceModalBottomWrapperText {
  margin-top: -24px;
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.marketplaceModalHelperText {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  -webkit-font-smoothing: subpixel-antialiased;

  color: var(--colorGrey300);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalHelperLink {
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  -webkit-font-smoothing: subpixel-antialiased;

  color: var(--colorGrey700);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

/* Modal Close icon */

.marketplaceModalCloseStyles {
  /* Position inside modal */
  position: fixed;
  top: 0px;
  right: 0; 

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  @media (--viewportMedium) {
    padding: 27px 30px;
    position: absolute;
    top: 40px;
  }

  /* Colors */
  background-color: transparent;
  color: var(--colorGrey700);

  cursor: pointer;
}
.marketplaceModalCloseStyles:enabled:hover {
  background-color: transparent;
  color: var(--colorBlack);
  box-shadow: none;
  text-decoration: none;
}
.marketplaceModalCloseStyles:enabled:active {
  background-color: transparent;
  color: var(--colorBlack);
}
.marketplaceModalCloseStyles:disabled {
  background-color: transparent;
}

.marketplaceModalIconStyles {
  height: 48px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.marketplaceModalCloseText {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalCloseIcon {
  display: inline-block;
  margin-left: 8px;

  /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;

  @media (--viewportMedium) {
    margin-top: 2px;
  }
}

.marketplaceModalErrorStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;

  margin-top: 24px;
  color: var(--colorFail);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.marketplaceModalPasswordMargins {
  /* Leave space between the input and the button below when the
  viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

/* 
.custompopupcard {
  box-shadow: none !important;
  max-width: 189px;
}
.hidefield {
  opacity: 0;
}
.customcomparepoints{
  margin-top: 130px;
} */


#featured-locations {

  & header {
    max-width: unset;
  }

  & > div {
    padding: 60px 0 96px;

    @media (--viewport1280) {
      padding: 100px 0 136px;
    }
  }

  & :global(#featured-locations-container) {
    padding: 0;
  }

  & [class*="BlockContainer_root"] {
    position: relative;
  }

  & [class*="BlockDefault_text"] {
    padding: 0;
    margin-top: 16px;

  & p {
    padding: 0;
    font-size: 18px;
    line-height: 1;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorBlack);
  }

  & a {
    font-weight: 900;
    color: var(--colorBlack);
    line-height: 30px;
  }

  & a::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

#video-bg-section {
  position: relative;
  margin: 0;

  & > div {
    padding: 0;
  }

  & header {
    max-width: unset;
  }

  & [class*="SectionBuilder_blockContainer"] {
    max-width: 100%;
    padding: 0;
  }

  & iframe {
    pointer-events: none;
    min-height: 350px;
    transform: scale(1.8);

    @media (--viewportSmall) {
      transform: scale(1.5);
    }

    @media (--viewportMedium) {
      top: -80px;
    }

    @media (--viewportLarge) {
      min-height: 540px;
    }

    @media (--viewport1280) {
      transform: scale(1);
    }
  }

  & [class*="BlockDefault_media"] {
    max-height: 350px;
    min-height: 350px;
    overflow: hidden;
    border-radius: 0;

    @media (--viewportLarge) {
      max-height: 540px;
      min-height: 540px;
    }
  }

  & [class*="YoutubeEmbed_video"] {
    overflow: visible;
  }

  & [class*="BlockDefault_text"] {
    position: absolute;
    bottom: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & h2 {
      padding: 0;
      margin: 0;
      color: var(--colorWhite);
      font-weight: 900;

      @media (--viewportMedium) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    & p {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    & p a {
      display: inline-block;
      width: 100%;
      max-width: 200px;
      height: 48px;
      line-height: 48px;
      margin: 10px 15px;

      padding: 0;

      transition: all var(--transitionStyleButton);
      cursor: pointer;

      font-weight: var(--fontWeightBold);

      text-align: center;
      text-decoration: none;

      border: none;
      border-radius: var(--borderRadiusMax);

      background-color: var(--marketplaceColor);
      color: var(--colorWhite);

      @media (--viewportMedium) {
        height: 60px;
        font-size: 16px;
        line-height: 60px;
      }

      &:focus,
      &:hover {
        outline: none;
        box-shadow: var(--boxShadowButton);
        background-color: var(--marketplaceColorDark);
      }
    }
  }
}

#latest-stories {
  padding: 60px 0 0;

  @media (--viewport1280) {
    padding: 100px 0 0;
  }

  & > div {
    padding: 0;
  }

  & header {
    justify-content: flex-start;
    max-width: unset;

    & h2 {
      padding: 0;
      margin: 0;
      color: var(--colorBlack);
      font-weight: 900;

      @media (--viewportMedium) {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }

  & header + div {
    padding: 0;
  }

  & #latest-stories-slider {
      padding: 50px 0 120px;

      @media (--viewport1280) {
        padding: 50px 0 160px;
      }
    }

    & [class*="BlockContainer_root"] {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 20px;

      & img {
        border-radius: var(--borderRadiusBig) var(--borderRadiusBig) 0 0;
        opacity: 1;
      }

    &:hover {
      box-shadow: var(--boxShadowListingCard);

      & h3,
      & a,
      & img,
      & [class*="BlockDefault_media"],
      & [class*="BlockDefault_text"] {
          transition: all .3s ease-out;
        }

      & [class*="BlockDefault_media"] {
        height: 0;
      }

      & [class*="BlockDefault_text"] {
        height: 100%;
      }

      & a {
        background-color: var(--marketplaceColorDark);
      }

      & img {
        opacity: 0;
      }
    }
  }

  & [class*="BlockDefault_media"] {
      height: 100%;
    }

  & [class*="BlockDefault_text"] {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      padding: 20px;
      flex-grow: 1;

    & h3 {
        padding: 0;
        margin: 0 0 20px;
        font-weight: 900;
        line-height: 30px;
        font-size: 18px;
        color: var(--colorBlack);
      }

    & a {
        margin: 0;
        max-width: 200px;
        justify-content: center;
        font-size: 16px;

      &:hover,
      &:focus {
        background-color: var(--marketplaceColorDark);
      }
    }
  }
}








.swiper-button-prev::after, .swiper-button-next::after {
	
	display: none;
}













.d-grid {
  display: grid;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--gutter-x, 0.75rem);
  padding-left: var(--gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
img {
  width: 100%;
}
.textCenter {
  text-align: center;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 95%
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px
  }
}
@media (min-width: 1441px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1240px;
    /* max-width: 1630px; */
  }
}

.smallWidth {
  max-width: 629px;
  margin: 0 auto;
  padding: 0 15px;
}
.mediumWidth {
  max-width: 800px;
  margin: 0 auto;
}
/* careCommunity */

.btnDefault {
  min-width: 190px;
  min-height: 56px;
  background: var(--marketplaceColor);
  border-radius: calc(var(--borderCommon) + 5px);
  color: #fff;
  border: 1px solid var(--marketplaceColor);
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-size: 16px;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-50%) scaleX(0);
    transition: 0.3s ease all;
    background: rgba(255, 255, 255, 0.1);
    transform-origin: center center;
  }
  &:hover {
    &:after {
      transform: translateX(-50%) scaleX(1);
    }
  }
}
button.btnOutline {
  background: transparent;
  color: var(--marketplaceColor);
  &:after {
    background: var(--marketplaceColor);
    z-index: -1;
  }
  &:hover {
    color: var(--colorWhite);
  }
  &.providerMsg{
    background-color: #FC695F;
    color: #fff;
    border-color: #FC695F;
    &:after {
      background: rgb(255 255 255 / 18%);
      z-index: -1;
    }
  }

}

.btnBlock {
  width: 100%;
}



body .swiper-button-prev, body .swiper-button-next {
  top: unset;
  bottom: 17px;
  width: 48px;
  height: 48px;
  border: 1px solid #C2C2CA;
  border-radius: 50%;
}
.swiper-button-next.swiper-button {
  right:calc(50% - -15px);
}
.swiper-button-prev.swiper-button svg {
  transform: rotateY(180deg);
}
body .swiper-button-prev svg path, body .swiper-button-next svg path {
  stroke: var(--marketplaceColor);
}

.swiper-button-prev.swiper-button {
  left: calc(50% - 0px);
}
body .swiper-button-prev svg, body .swiper-button-next svg {
  width: 20px;
  height: 20px;
  object-fit: contain;
  transform-origin: center;
}
.swiper-button-prev.swiper-button-disabled svg path, .swiper-button-next.swiper-button-disabled svg path {
  stroke: gray;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: absolute;
  max-width: max-content;
  left: calc(50% - 140px);
  transform: translate(-5px, -20px);
}

body .swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 30px;
  background-color: var(--marketplaceColor);
}


@media screen and (max-width:767px){
  body .swiper-button-prev, body .swiper-button-next{
    display: none;
   }
   .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    transform: translate(-50%,-50%);
    left: 50%;
}
}

button {
  &.applyFilterButton {
    min-height: 40px;
    margin-top: 24px;
  }
}


.listView{
  grid-template-columns: repeat(1, 1fr);
  .listingCard{
    flex-direction: row;
    .imageWrapper{
      flex: 0 0 30%;
       max-width: 30%;
    }
  }
}
/* .custom-search div{
  position: st !important;
} */


.react-datepicker {
 
  width: 100%;

}
.react-datepicker__month-container {
  float: left;
  width: 100%;
  /* background: red; */
  background: #e8f5ff;

}
.provider-main .react-datepicker__month-container{
    background: #F3E4F5;

}

.provider-main .react-datepicker__header {
  background-color: #F3E4F5 !important;

}
.CalendarBookings_calendarContainer__lZr3k {
  padding: 0px!important;
  border-radius: 24px!important;
  margin-top: 24px;
}
.react-datepicker { 
  background-color: #e8f5ff;
  border: 0px solid #262BBA40!important;
  border-radius: 24px!important;
  overflow: hidden;

}
.react-datepicker__header {  
  background-color: #e8f5ff!important;
  border-bottom: 0px solid #aeaeae!important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-family: Sora;
  font-size: 12px;
  font-weight: 600;
  /* line-height: 14.4px; */
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.CalendarBookings_calendarContainer__lZr3k {
  background-color: transparent!important; 
  box-shadow: unset!important;
}

.legend div {
  display: flex;
  align-items: center;
  font-family: Sora;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
p.booking-instruction {
  color: #5d5d5d;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}