.partnerCardBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  align-items: center;
  padding-bottom: 64px;
  max-width: 1270px;
  margin: 0 auto;
  /* padding: 0 15px; */
  .partnerColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2{
      color: #333333;
    }
    p {
      margin: 16px 0 40px;
      text-align: left;
      line-height: 32px;
    }
    .partnersCounts {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      .countTextBlock {
        .totalPartnership {
          font-family: Sora;
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
          text-align: left;

          color: #333333;
        }
        .titlePrtnership {
          font-family: Sora;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
          color: #666666;
        }
      }
      @media screen and (width < 992px){
        margin-bottom: 25px;
      }
    }
  }
  .partnerImageBlock {
    .partnerImage {
        border-radius: 16px;
        overflow: hidden;
      img {
      }
    }
  }
  &.flipCard{
    align-items: unset;
    padding: 64px 0 0;
    .partnerColumn{
        order: 1;
        justify-content: space-between;
        h2{
            text-align: left;
        }
        .partnerIconBlock{
            margin-bottom: 16px;
        }
        .partnersCounts {            
            gap: 35px;
        
          }
          
    }
  }
  &.flipImgCard{
    .partnerColumn{
        order: unset;
       
    }
  }

  @media screen and (width < 992px){
    grid-template-columns: repeat(1, 1fr);
  }
}
