@import '../../styles/customMediaQueries.css';

.tooltip {
  position: relative;
  display: inline-block;
  transform: translate(5px, -2px);
}

.tooltipText {
  opacity: 0;
  visibility: hidden;
  min-width: 200px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-15%, -110%);
  padding: 12px;
  border-radius: 8px;
  box-shadow: var(--boxShadowPopupLight);
  background-color: var(--colorWhite);

  @media (--viewportSmall) {
    transform: translate(15%, -100%);
  }
}

.tooltip svg:hover,
.tooltip svg:focus {
  cursor: pointer;

  & + .tooltipText {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
}