 h2{  
    font-family: Sora;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    span{
        color: #262BBA; 
    } 
    @media screen and (max-width:550px){
      
         font-size: 30px;
         line-height: normal;
       
     }   
}
p{
   
    font-family: Sora;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #666666;
    svg{
        fill: none;
    }
     
}
